.alert {
	border-radius: 0 !important;
}

.icon {
	flex-shrink: 0;
}

.title {
	font-weight: bold !important;
	line-height: 2.5rem;
	min-height: 3rem;
}
