$ICON_DIMS: 4.5rem;

.buttons {
	background: var(--palette-background-paper);
	bottom: 0;
	padding-bottom: 2.5rem;
	padding-top: 1.5rem;
	position: sticky;
}

.header {
	background: var(--palette-background-paper);
	margin: -1.5rem 0;
	padding: 1.5rem 0;
	position: sticky;
	top: 0;
	z-index: 10;
}

.icon {
	align-self: center;
	height: $ICON_DIMS !important;
	width: $ICON_DIMS !important;
	justify-self: center;
}
