.root {
	background-color: #333;
	border-radius: 0;
	bottom: 0;
	color: #fff;
	left: 50%;
	position: fixed;
	transform: translateX(-50%);
	width: 100%;
	z-index: 10;

	a {
		color: #fff;
	}

}

.alert {
	padding: 1em 0;
}

.button {
	min-width: 20em !important;
}