.AppBar {
	padding: 0 1.5rem;

	@media all and (min-width: 400px) {
		padding: 0 3rem;
	}
}

.CheckoutBadge {
	>* {
		font-size: 1.6rem !important;
	}
}


.Container {
	grid-template-columns: 18rem 1fr;

	@media all and (max-width: 960px) {
		grid-template-columns: 12rem 1fr;
	}

	@media all and (max-width: 400px) {
		grid-template-columns: 6rem 1fr;
	}
}

.NavLink {
	color: hsla(0 , 0%, 100%, .55) !important;
	font-size: 1.5em !important;
	text-transform: none !important;

	&:hover {
		color: #fff !important;
	}
}

.NavLinkActive {
	color: #fff !important;
	font-size: 1.5em !important;
	text-transform: none !important;
	font-weight: bold !important;
}

.IconButtonContainer {
	min-width: 13rem;
}

.IconButtonContainer_small {
	min-width: 8rem;
}

.LogoButton {
	display: flex;
	height: 100%;
	overflow: hidden;
}
