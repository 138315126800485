.buttons {
	background: var(--palette-background-paper);
	bottom: 0;
	padding-bottom: 2.5rem;
	padding-top: 1.5rem;
	position: sticky;
}

.errorsUl {
	padding-left: 3rem;

	li:not(:last-child) {
		margin-bottom: 0.375rem;
	}
}

.header {
	align-items: center;
	background: var(--palette-background-paper);
	padding: 1.5rem 0;
	position: sticky;
	top: 0;
	z-index: 10;
}
