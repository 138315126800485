.headerContentContainerImaged {
	display: grid;
	gap: 3rem;
	grid-template-columns: 3fr 1fr;

	@media all and (max-width: 780px) {
		gap: 1.5rem;
		grid-template-columns: 1fr;

		.headerImg {
			height: 25vh;
			justify-self: center;
			max-height: 32rem;
			object-fit: cover;
			width: 100%;
		}
	}
}
