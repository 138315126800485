.root {
	box-shadow: 0 0.25rem 0.5rem 0 rgba(0,0,0,0.15) !important;
	overflow: initial;
	transition: all 0.3s !important;

	&:global(.hover-transition) {
		&:global(:hover),
		&:global(:focus),
		&:global(:focus-within) {
			box-shadow: 0 0.5rem 1.5rem 0 rgba(0,0,0,0.15) !important;
		}
	}
}
