.price {
	flex-shrink: 0;
}

.seatReservationsBadge {
	:global(.MuiBadge-badge) {
		scale: 1.8;
	}
}

.seatReservationsBtn {
	font-size: 1.1em;
	text-transform: none;
}

.summary {
	cursor: default;
}
