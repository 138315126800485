* {
	border: 0;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

html {
	font-family: Arial, sans-serif, serif;
	font-size: 62.5%;
	font-weight: normal;
	overflow-y: scroll;
}

body, html {
	min-height: 100%;
	min-width: 100%;
}

html {
	scroll-padding-top: 10.5rem;
}

ul.hops li:not(:last-child) {
	margin-bottom: 0.75rem;
}

@media (orientation: landscape) {
	html {
		-webkit-text-size-adjust: 100%;
	}
}

#notistack-snackbar {
	font-size: 1.4rem;
}

.MuiTooltip-tooltip {
	font-size: 1.2rem !important;
}

[palette-type="dark"] {
	::-webkit-calendar-picker-indicator {
		filter: invert(1);
	}
}

::-webkit-calendar-picker-indicator {
	margin-right: -0.75rem;		// see `DatePicker.module.scss`
}

::-webkit-datetime-edit {
	color: transparent;
}

:focus::-webkit-datetime-edit,
.has-value::-webkit-datetime-edit {
	color: currentColor;
}
