.root {
	cursor: pointer;
	width: fit-content;

	&:global(.disabled) {
		cursor: default;
		opacity: 0.5;
		text-decoration: none !important;
	}
	&:global(:not(.disabled):focus), &:global(:not(.disabled):hover) {
		text-decoration: underline !important;
	}
}

.inline {
	display: inline-block;
}
