.invertedTotalRowOrder {
	.divider {
		order: -1;
	}
	.totalRow {
		order: -2;
	}
	.totalRowContainer {
		order: -1;
	}
}

.sticky {
	max-height: calc(100vh - 12rem);
	overflow-y: auto !important;
	position: sticky;
	top: 10.5rem;

	.totalRowContainer {
		background: var(--palette-background-paper);
		bottom: 0;
		margin-bottom: -1.5rem;
		padding-bottom: 1.5rem;
		position: sticky;
		z-index: 10;
	}
}
