.logo img {
	transition: transform 0.2s ease-out;
}

.logo:global(:hover) img,
.logo:global(:focus) img {
	transform: scale(1.1);
}

.logo:global(:focus) {
	border: 0.1rem solid #999;
}
